import { Error, GetWorkStartAtDto, GetWorkStatusDto, ResponseDto, StartEndParam, WorkSaveDto } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class WorkController<SecurityDataType = unknown> {
    http: HttpClient<SecurityDataType>

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http
    }

    /**
     * No description
     *
     * @tags work-controller
     * @name UpdateMyWork
     * @summary 근태 수정
     * @request PUT:/api/work/{id}
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    updateMyWork = (id: number, data: StartEndParam, params: RequestParams = {}) =>
        this.http.request<ResponseDto, Error>({
            path: `/api/work/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            ...params,
        })
    /**
     * No description
     *
     * @tags work-controller
     * @name DeleteMyWork
     * @summary 근태 삭제
     * @request DELETE:/api/work/{id}
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    deleteMyWork = (id: number, params: RequestParams = {}) =>
        this.http.request<ResponseDto, Error>({
            path: `/api/work/${id}`,
            method: 'DELETE',
            ...params,
        })
    /**
     * No description
     *
     * @tags work-controller
     * @name EndMyWork
     * @summary 근태 종료
     * @request PUT:/api/work/{id}/end
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    endMyWork = (id: number, params: RequestParams = {}) =>
        this.http.request<ResponseDto, Error>({
            path: `/api/work/${id}/end`,
            method: 'PUT',
            ...params,
        })
    /**
     * No description
     *
     * @tags work-controller
     * @name GetCurrentMyWorkList
     * @summary 근태현황(오늘 포함 된 건들) 출퇴근 제외
     * @request GET:/api/work
     * @response `200` `(GetWorkStartAtDto)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getCurrentMyWorkList = (params: RequestParams = {}) =>
        this.http.request<GetWorkStartAtDto[], Error>({
            path: `/api/work`,
            method: 'GET',
            ...params,
        })
    /**
     * No description
     *
     * @tags work-controller
     * @name SaveMyWork
     * @summary 근태 등록
     * @request POST:/api/work
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    saveMyWork = (data: WorkSaveDto, params: RequestParams = {}) =>
        this.http.request<ResponseDto, Error>({
            path: `/api/work`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            ...params,
        })
    /**
     * No description
     *
     * @tags work-controller
     * @name GetMyWorkStartAt
     * @summary 근태이력(특정일) 출퇴근 제외
     * @request GET:/api/work/start-at/{startAt}
     * @response `200` `(GetWorkStartAtDto)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getMyWorkStartAt = (startAt: number, params: RequestParams = {}) =>
        this.http.request<GetWorkStartAtDto[], Error>({
            path: `/api/work/start-at/${startAt}`,
            method: 'GET',
            ...params,
        })
    /**
     * No description
     *
     * @tags work-controller
     * @name GetMyWorkStatus
     * @summary 현재 근태상태
     * @request GET:/api/work-status
     * @response `200` `GetWorkStatusDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getMyWorkStatus = (params: RequestParams = {}) =>
        this.http.request<GetWorkStatusDto, Error>({
            path: `/api/work-status`,
            method: 'GET',
            ...params,
        })
}

import Head from 'next/head'
import React, { ReactElement, ReactNode } from 'react'
import MenuTab from './MenuTab'

type Props = {
    children?: ReactNode
    title?: string
    isActiveMenu?: boolean
}

const LeftNavigation = ({ children, title = 'Smart Office', isActiveMenu = true }: Props): ReactElement => (
    <>
        <Head>
            <title>{title}</title>
        </Head>
        <div className='wrapper'>
            {isActiveMenu && <MenuTab />}
            {children}
        </div>
    </>
)

export default LeftNavigation

import getDayOfYear from 'date-fns/getDayOfYear'
import { CONST } from '../domain/const'

export {}

declare global {
    interface String {
        getDate(): string

        getTime(isAmPM?: boolean): string

        getTimeLabel(): string

        isAvailableMeetingTime(): boolean

        isActiveMeeting(endAt: string): boolean

        timeStamp(isSeconds?: boolean, isZeroTime?: boolean): number

        toFormatDate(separator?: string): string

        toNormalFormatDate(separator?: string): string

        toDateIsBefore(dateString: string): boolean
    }

    interface Number {
        paddingHour(isAmPM?: boolean): string

        paddingMinute(): string
    }

    interface Date {
        toFormatDate(separator?: string, showWeek?: boolean): string

        toFormatHour(isAmPm?: boolean, useSeconds?: boolean): string

        getAMPM(): string

        getAMPMKR(): string

        normalFormat(): string

        mergeTimeWithYearMonthDay(dateString: string): number

        isToday(): boolean

        isSameDay(compareDate: Date): boolean
    }
}
Date.prototype.getAMPM = function() {
    return this.getHours() / 12 >= 1 ? 'PM' : 'AM'
}

Date.prototype.getAMPMKR = function() {
    return this.getHours() / 12 >= 1 ? '오후' : '오전'
}

Date.prototype.toFormatHour = function(isAmPm = false, useSeconds = false) {
    const hour = this.getHours().paddingHour(isAmPm)
    const minutes = ':' + this.getMinutes().paddingMinute()
    const seconds = useSeconds ? ':' + this.getSeconds().paddingMinute() : ''
    return hour + minutes + seconds
}


function getWeekLabel(date: Date) {
    const week = ['일', '월', '화', '수', '목', '금', '토']
    const todayDay = date.getDay()
    return `(${week[todayDay]})`
}


Date.prototype.toFormatDate = function(separator = '-', showWeek = false) {
    const year = `${this.getFullYear()}`
    const month = `${this.getMonth() + 1}`
    const day = `${this.getDate()}`
    const week = showWeek ? ' ' + getWeekLabel(this) : ''
    return `${year}${separator}${month.padStart(2, '0')}${separator}${day.padStart(2, '0')}${week}`
}

Date.prototype.normalFormat = function() {
    return `${this.toFormatDate('.')} (${CONST.DAYS[this.getDay()]})`
}

Date.prototype.isToday = function() {
    return getDayOfYear(this) === getDayOfYear(new Date())
}

Date.prototype.isSameDay = function(compareDate: Date) {
    return getDayOfYear(this) === getDayOfYear(compareDate)
}

Number.prototype.paddingHour = function(isAmPM = true) {
    const number = Number(this)
    const prefix = isAmPM ? number / 12 >= 1 ? 'PM' : 'AM' : ''

    return `${prefix} ${`${number !== 12 ? number % 12 : number}`.padStart(2, '0')}`
}

Number.prototype.paddingMinute = function() {
    return this.toString().padStart(2, '0')
}

String.prototype.getTime = function(isAmPM = true) {
    const str = String(this)
    const date = new Date(str)
    let hours = date.getHours()
    const minutes = date.getMinutes()

    const ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12

    return `${isAmPM ? `${ampm} ` : ''}${`${hours}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')}`
}

String.prototype.getDate = function() {
    const date = new Date(String(this))

    const month = (date.getMonth() + 1).toString().length == 1 ? '0' + (date.getMonth() + 1).toString() : date.getMonth() + 1
    const day = date.getDate().toString().length == 1 ? '0' + date.getDate().toString() : date.getDate()
    return `${date.getFullYear()}-${month}-${day}`
}

String.prototype.getTimeLabel = function() {
    const str = String(this)
    const date = new Date(str)
    const hours = date.getHours()
    const minutes = date.getMinutes()
    return `${hours.paddingHour(true)}:${`${minutes}`.padStart(2, '0')}`
}

String.prototype.isAvailableMeetingTime = function() {
    const current = new Date()
    const target = new Date(String(this))
    console.log(target)
    return target.getTime() - current.getTime() > 0
}
String.prototype.isActiveMeeting = function(endAt: string) {
    const str = String(this)
    const current = new Date()
    const start = new Date(str)
    const end = new Date(endAt)

    return end.getTime() - current.getTime() >= 0 && current.getTime() - start.getTime() >= 0
}

String.prototype.timeStamp = function(isSeconds = true, isZeroTime = true) {
    const str = String(this)
    const date = Date.parse(str)
    const _date = new Date(date)
    if (isZeroTime) {
        _date.setHours(0, 0, 0, 0)
    }
    return isSeconds ? _date.getTime() / 1000 : _date.getTime()
}

String.prototype.toFormatDate = function(separator = '-') {
    const str = String(this)
    const date = new Date(str)
    const year = `${date.getFullYear()}`
    const month = `${date.getMonth() + 1}`
    const day = `${date.getDate()}`
    return `${year}${separator}${month.padStart(2, '0')}${separator}${day.padStart(2, '0')}`
}

String.prototype.toNormalFormatDate = function(separator = '-') {
    const str = String(this)
    const date = new Date(str)
    const year = `${date.getFullYear()}`
    const month = `${date.getMonth() + 1}`
    const day = `${date.getDate()}`
    return `${year}${separator}${month.padStart(2, '0')}${separator}${day.padStart(2, '0')} (${
        CONST.DAYS[date.getDay()]
    })`
}

String.prototype.toDateIsBefore = function(dateString: string) {
    const startAt = new Date(parseInt(this.toString()) * 1000)
    const endAt = new Date(parseInt(dateString) * 1000)
    return getDayOfYear(startAt) < getDayOfYear(endAt)
}

Date.prototype.mergeTimeWithYearMonthDay = function(dateString: string) {
    const dates = dateString.split('-')
    this.setFullYear(parseInt(dates[0]), parseInt(dates[1]) - 1, parseInt(dates[2]))
    return this.getTime() / 1000
}

import { useRouter } from 'next/router'
import * as React from 'react'

const useRouteLoading = () => {
    const router = useRouter()
    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
        const start = () => {
            setLoading(true)
        }
        const end = () => {
            setLoading(false)
        }
        router.events.on('routeChangeStart', start)
        router.events.on('routeChangeComplete', end)
        router.events.on('routeChangeError', end)
        return () => {
            router.events.off('routeChangeStart', start)
            router.events.off('routeChangeComplete', end)
            router.events.off('routeChangeError', end)
        }
    }, [])
    return [loading, setLoading]
}

export default useRouteLoading

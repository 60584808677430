import { AxiosResponse } from 'axios'
import useSWR, { SWRResponse } from 'swr'
import { SWRConfiguration } from 'swr/dist/types'
import {
    FindByFloorAndCountMeetingDto,
    GetAllByRoomReservationDtoList,
    GetFloorSeatCountDto,
    GetMeetingRoomReservationDto,
    GetReservationDto,
    GetWorkStatusDto,
    ReservationConfirmDto,
} from '../__generated__/data-contracts'
import { clientKioskController } from './kioskController'
import { clientMeetingRoomReservationController } from './meetingRoomReservationController'
import { clientMyReservationController } from './myReservationController'
import { clientWorkController } from './workController'

const defaultSwrOptions: SWRConfiguration = { refreshInterval: 10000, revalidateOnMount: true }
const returnData = <T>(res: AxiosResponse<T>) => res.data

export const WorkStatusSwr = (): SWRResponse<GetWorkStatusDto, Error> => {
    return useSWR<GetWorkStatusDto>(
        '/api/work-status',
        () => clientWorkController.getMyWorkStatus().then(returnData),
        defaultSwrOptions
    )
}

export const MyCurrentReservations = (): SWRResponse<GetReservationDto, Error> => {
    return useSWR<GetReservationDto>(
        `/api/v1/meeting-rooms/current-reservations`,
        () => clientMyReservationController.getMyCurrentReservations().then(returnData),
        defaultSwrOptions
    )
}

export const MyConfirmations = (): SWRResponse<ReservationConfirmDto, Error> => {
    return useSWR<ReservationConfirmDto>(
        `/api/reservations/confirmations`,
        () => clientMyReservationController.getMyConfirmation().then(returnData),
        defaultSwrOptions
    )
}

export const FloorRoomsWithCount = (floorId: number): SWRResponse<FindByFloorAndCountMeetingDto[], Error> => {
    return useSWR(
        `/kiosk/maps/floors/${floorId}/meeting-rooms`,
        () => clientKioskController.findByFloorAndCount(floorId).then(returnData),
        defaultSwrOptions
    )
}

export const FloorSeatsWithCount = (): SWRResponse<GetFloorSeatCountDto[], Error> => {
    return useSWR<GetFloorSeatCountDto[]>(
        `/api/kiosk/maps/floors/seats`,
        () => clientKioskController.getFloorSeatCount().then(returnData),
        defaultSwrOptions
    )
}

export const MeetingRoomReservationListByStartAt = (
    roomId: number,
    startAt: number
): SWRResponse<GetAllByRoomReservationDtoList, Error> => {
    return useSWR<GetAllByRoomReservationDtoList>(
        `/api/meeting-rooms/${roomId}/reservations/start-at/${startAt}`,
        () =>
            clientMeetingRoomReservationController
                .getMeetingRoomReservationListByStartAt(roomId, startAt)
                .then(returnData),
        defaultSwrOptions
    )
}

export const MeetingRoomReservationsByStartAt = (startAt: number): SWRResponse<GetMeetingRoomReservationDto[]> => {
    return useSWR(
        `/api/meeting-rooms/reservations/start-at/${startAt}`,
        () => clientMeetingRoomReservationController.getRoomReservationsByStartAt(startAt).then(returnData),
        defaultSwrOptions
    )
}

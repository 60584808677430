import React from "react";
import { ConfigProvider } from "antd";
import koKR from "antd/locale/ko_KR";

const withTheme = (node: JSX.Element) => (
    <>
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#333466',
                    borderRadius: 5,
                    fontFamily: "Noto Sans KR, apple-system, sans-serif, serif",
                },
                components: {
                    Button: {
                        primaryShadow : '0 2px 0 rgba(0, 0, 0, 0.02)'
                            },
                 }
            }}
            locale={koKR}
        >
            {node}
        </ConfigProvider>
    </>
);

export default withTheme;

export {}

declare global {
    interface BridgeInfo {
        token?: string
    }

    interface Bridge {
        info: BridgeInfo
        tokenToWeb: (token: string) => void
        setSessionCookie: (session: string) => void
    }

    interface HandlerAction {
        postMessage: (data?: any) => void
    }

    interface Webkit {
        messageHandlers: {
            vibrate: HandlerAction
            getToken: HandlerAction
            changeUrl: HandlerAction
            saveLogin: HandlerAction
            logout: HandlerAction
            gotoFirst: HandlerAction
            getSaveInfo: HandlerAction
            saveInfo: HandlerAction
            saveDelete: HandlerAction
        }
    }

    interface Android {
        qrScanner: () => void
        vibrate: () => void
        getToken: (data?: any) => void
        changeUrl: (data?: any) => void
        saveLogin: (data?: any) => void
        logout: (data?: any, userId?: string) => void
        gotoFirst: (data?: any) => void
        saveInfo: (userId: string, userPw: string) => void
        saveDelete: (data?: any) => void
        getSaveInfo: (data?: any) => void
    }

    interface Window {
        bridge: Bridge
        webkit: Webkit
        // for aos
        android: Android
        // for kiosk
        kiosk: any
    }
}

;(function() {
    if (typeof navigator === 'undefined') return
    if (window.bridge === undefined)
        window.bridge = {
            info: { token: '' },
            tokenToWeb: data => {
                window.bridge.info.token = data
            },
            setSessionCookie: data => {
                setCookie('sofi', data, 30)
            },
        }
})()

const setCookie = function(name, value, exp) {
    const date = new Date()
    date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000)
    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/'
}

import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import Link from 'next/link'
import * as React from 'react'
import { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import mainstyles from '../../styles/m/menuTab.module.css'
import mainstylesWeb from '../../styles/web/menuTab.module.css'
import IconList from './IconList'

interface MenuItem {
    label: string
    icon: ReactElement
    activeIcon: ReactElement
    path: string
}

const MenuTab: FC = () => {
    const [pathname, setPathname] = useState('')
    const [isWeb, setIsWeb] = useState(false)
    useEffect(() => {
        setPathname(location.pathname)
        const isMobile = /Mobi|Android/i.test(navigator.userAgent)
        setIsWeb(!isMobile)

        const path = isMobile ? '/m/' : '/web/'
        if (location.pathname.indexOf(path) !== 0) {
            location.replace(location.href.replace(/\/(web|m)\//, path))
        }
    }, [])
    // console.log(window.location.href, window.location.href.includes('/web/home'))
    const isActive = useCallback((it: MenuItem) => {
        return pathname.indexOf(it.path) === 0
    }, [pathname])
    const contextPath = isWeb ? '/web' : '/m'

    const menuItems: MenuItem[] = [
        {
            label: '홈',
            icon: <IconList homeIcon isWeb={isWeb} />,
            activeIcon: <IconList clickHomeIcon isWeb={isWeb} />,
            path: `${contextPath}/home`,
        },
        {
            label: '도면 보기',
            icon: <IconList mapIcon isWeb={isWeb} />,
            activeIcon: <IconList clickMapIcon isWeb={isWeb} />,
            path: `${contextPath}/map`,
        },
        {
            label: '회의실 현황',
            icon: <IconList meetingRoomStatus isWeb={isWeb} />,
            activeIcon: <IconList clickMeetingRoomStatus isWeb={isWeb} />,
            path: `${contextPath}/situation`,
        },
        {
            label: '투데이',
            icon: <IconList todayIcon isWeb={isWeb} />,
            activeIcon: <IconList clickTodayIcon isWeb={isWeb} />,
            path: `${contextPath}/mymeeting`,
        },
        {
            label: '회의 이력',
            icon: <IconList historyReservation isWeb={isWeb} />,
            activeIcon: <IconList clickHistoryReservation isWeb={isWeb} />,
            path: `${contextPath}/roomHistory`,
        },
        {
            label: '좌석 이력',
            icon: <IconList historyReservation isWeb={isWeb} />,
            activeIcon: <IconList clickHistoryReservation isWeb={isWeb} />,
            path: `${contextPath}/seatHistory`,
        },
        // {
        //     label: '근태 이력',
        //     icon: <IconList workHistory isWeb={isWeb} />,
        //     activeIcon: <IconList clickWorkHistory isWeb={isWeb} />,
        //     path: `${contextPath}/myWorkingHistory`,
        // },
        // {
        //     label: '방문자 이력',
        //     icon: <IconList visitorHistoryIcon isWeb={isWeb} />,
        //     activeIcon: <IconList clickVisitorHistoryIcon isWeb={isWeb} />,
        //     path: `${contextPath}/visitorHistory`,
        // },
        {
            label: '카테고리',
            icon: <IconList category isWeb={isWeb} />,
            activeIcon: <IconList clickCategory isWeb={isWeb} />,
            path: `${contextPath}/setting`,
        },
    ]

    return (isWeb ? (
        <nav className={mainstylesWeb.nav_style}>
            <Drawer
                variant='permanent'
                anchor='left'
                open
                sx={{
                    display: 'block',
                    zIndex: 1,
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '280px' },
                }}
            >
                {/*<Toolbar sx={{ paddingY: '16px', height: '120px' }}>*/}
                {/*    <Link href='/web/home'>*/}
                {/*        <img*/}
                {/*            src='/ctrl/api/v2/logo'*/}
                {/*            style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%', width: '100%' }}*/}
                {/*            alt='logo image'*/}
                {/*        />*/}
                {/*    </Link>*/}
                {/*</Toolbar>*/}
                <Divider />
                <List>
                    {menuItems.map((it) => (
                        <Link href={it.path} key={it.path}>
                            <ListItem>
                                <ListItemButton selected={isActive(it)}>
                                    <ListItemIcon>{isActive(it) ? it.activeIcon : it.icon}</ListItemIcon>
                                    <ListItemText primary={it.label} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))}
                </List>
            </Drawer>
        </nav>
    ) : (
        //모바일
        <nav className={mainstyles.control}>
            <ul className={mainstyles.ul}>
                {menuItems.map((it) => (
                    <Link href={it.path} key={it.path}>
                        <li className={mainstyles.menu_item}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    height: '80%',
                                }}
                            >
                                {isActive(it) ? it.activeIcon : it.icon}
                                <div
                                    style={isActive(it) ? {
                                        fontSize: '10px',
                                        color: '#333465',
                                        fontWeight: '700',
                                        width: '100%',
                                        textAlign:"center",
                                        letterSpacing:"-1.1px"
                                    } : {
                                        fontSize: '10px',
                                        color: '#505050',
                                        width: '100%',
                                        textAlign:"center",
                                        letterSpacing:"-1.1px"
                                    }}
                                >
                                    {it.label}
                                </div>
                            </div>
                        </li>
                    </Link>
                ))}
            </ul>
        </nav>
    ))
}

export default MenuTab

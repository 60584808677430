import 'bootstrap/dist/css/bootstrap.min.css'
import * as React from 'react'
import { Button, ButtonGroup, Modal } from 'react-bootstrap'

interface Props {
    message?: string
    show?: boolean
    ok: React.MouseEventHandler<HTMLButtonElement>
    cancel: React.MouseEventHandler<HTMLButtonElement>
    size?: 'sm' | 'lg' | 'xl'
}

const ConfirmModal = ({ message, show, ok, cancel, size }: Props) => {
    return (
        <>
            <Modal
                size={size}
                show={show}
                centered
                backdrop={'static'}
                keyboard={false}
                style={{ width: '70vw', position: 'absolute', bottom: '50%', left: '15%' }}
            >
                <Modal.Header style={{ paddingBottom: '0', borderBottom: 'none' }}></Modal.Header>
                <Modal.Body
                    style={{
                        background: '#ffffff',
                        minHeight: '160px',
                        display: 'flex',
                        textAlign: 'center',
                        flexDirection: 'column',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ flex: '0 1', verticalAlign: 'middle', fontWeight: '600' }}>
                        <p>
                            <small>{message}</small>
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ padding: 0, margin: 0 }}>
                    <ButtonGroup style={{ width: '100%', minHeight: '60px', padding: 0, margin: 0 }}>
                        <Button className='btn btn-light w-100 p-0 m-0' onClick={ok}>
                            예
                        </Button>
                        <Button
                            className='btn btn-dark w-100'
                            style={{ backgroundColor: '#0c1946', color: 'white' }}
                            onClick={cancel}
                        >
                            아니오
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConfirmModal

import 'bootstrap/dist/css/bootstrap.min.css'
import * as React from 'react'
import { ReactElement, useEffect, useState } from 'react'
import { Button, ButtonGroup, Modal } from 'react-bootstrap'
import LoadingIndicator from './LoadingIndicator'

interface Props {
    show: boolean
    setShow: (show: boolean) => void
    size: 'sm' | 'lg' | 'xl'
}

const InspectionModal = ({ show, setShow, size = 'sm' }: Props): ReactElement => {
    const [loading, setLoading] = useState(false)
    const onClickReload = () => {
        setShow(false)
        setLoading(true)
    }

    useEffect(() => {
        if (loading) {
            window.location.reload()
        }
    }, [loading])

    return (
        <>
            {loading ? (
                <LoadingIndicator />
            ) : (
                <Modal
                    size={size}
                    show={show}
                    centered
                    backdrop={'static'}
                    keyboard={false}
                    style={{ width: '70vw', position: 'absolute', bottom: '50%', left: '15%' }}
                >
                    <Modal.Header style={{ paddingBottom: '0', borderBottom: 'none' }}></Modal.Header>
                    <Modal.Body
                        style={{
                            background: '#ffffff',
                            minHeight: '160px',
                            display: 'flex',
                            textAlign: 'center',
                            flexDirection: 'column',
                            alignContent: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ flex: '0 1', verticalAlign: 'middle', fontWeight: '600' }}>
                            <p>
                                <strong>
                                    현재 긴급 점검 중 입니다. <br /> 잠시후 다시 이용해주세요.
                                </strong>
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ padding: 0, margin: 0 }}>
                        <ButtonGroup style={{ width: '100%', minHeight: '60px', padding: 0, margin: 0 }}>
                            <Button className='btn btn-light w-100 p-0 m-0' onClick={onClickReload}>
                                새로고침
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}

export default InspectionModal

import { HttpClient } from '../../__generated__/http-client'
import { HttpHeader } from '../../domain/const'

const httpClient = new HttpClient({
    baseURL: '/ctrl',
    secure: true,
    securityWorker: (securityData: string | null) => ({ headers: { [HttpHeader.USER_TOKEN]: securityData } }),
})

export default httpClient

import { LoginDto } from '../__generated__/data-contracts'

export enum DeviceType {
    VIDEO_CALL = 'VIDEO_CALL',
    WHITE_BOARD = 'WHITE_BOARD',
    PROJECTOR = 'PROJECTOR',
    TV = 'TV',
}

export enum SeatStatus {
    IN_USE = 'IN_USE',
    ENABLE = 'ENABLE',
    DISABLE = 'DISABLE',
}

export interface BasePageProps {
    user?: LoginDto
}

export interface ErrorResponse {
    code: string
    data: string
}

export enum ConfirmType {
    START = 'START', // 최종 시작 확인 알림
    USING = 'USING' // 사용중 확인
}

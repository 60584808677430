import { Error, GetReservationDto, ReservationConfirmDto, ResponseDto } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class MyReservationController<SecurityDataType = unknown> {
    http: HttpClient<SecurityDataType>

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http
    }

    /**
     * No description
     *
     * @tags my-reservation-controller
     * @name ConfirmReject
     * @summary confirm 취소
     * @request PUT:/api/reservations/confirmations/{confirmId}/reject
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    confirmReject = (confirmId: number, params: RequestParams = {}) =>
        this.http.request<ResponseDto, Error>({
            path: `/api/reservations/confirmations/${confirmId}/reject`,
            method: 'PUT',
            ...params,
        })
    /**
     * No description
     *
     * @tags my-reservation-controller
     * @name ConfirmAccept
     * @summary confirm 확인
     * @request PUT:/api/reservations/confirmations/{confirmId}/accept
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    confirmAccept = (confirmId: number, params: RequestParams = {}) =>
        this.http.request<ResponseDto, Error>({
            path: `/api/reservations/confirmations/${confirmId}/accept`,
            method: 'PUT',
            ...params,
        })
    /**
     * @description 가장 최근 회의실,좌석 조회
     *
     * @tags my-reservation-controller
     * @name GetMyCurrentReservations
     * @summary 가장 최근 회의실,좌석 조회
     * @request GET:/api/reservations
     * @response `200` `GetReservationDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getMyCurrentReservations = (params: RequestParams = {}) =>
        this.http.request<GetReservationDto, Error>({
            path: `/api/reservations`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 회의실,좌석 전체 예약 조회
     *
     * @tags my-reservation-controller
     * @name GetMyReservations
     * @summary 회의실, 좌석 전체 예약 조회(특정 시간)
     * @request GET:/api/reservations/start-at/{startAt}
     * @response `200` `GetReservationDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getMyReservations = (startAt: number, params: RequestParams = {}) =>
        this.http.request<GetReservationDto, Error>({
            path: `/api/reservations/start-at/${startAt}`,
            method: 'GET',
            ...params,
        })
    /**
     * No description
     *
     * @tags my-reservation-controller
     * @name GetMyConfirmation
     * @summary confirm 필요한 건들
     * @request GET:/api/reservations/confirmations
     * @response `200` `ReservationConfirmDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getMyConfirmation = (params: RequestParams = {}) =>
        this.http.request<ReservationConfirmDto, Error>({
            path: `/api/reservations/confirmations`,
            method: 'GET',
            ...params,
        })
}

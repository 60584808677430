import { HttpClient } from '../../__generated__/http-client'
import { HttpHeader } from '../../domain/const'

const httpServer = new HttpClient({
    baseURL: process.env.BASE_URL,
    secure: true,
    securityWorker: (securityData: string | null) => ({ headers: { [HttpHeader.USER_TOKEN]: securityData } }),
})

export default httpServer

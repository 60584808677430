export const yyyymmdd = (date: Date): string => {
    //yyyy-mm-dd
    const year = date.getFullYear()
    const month = ('0' + (1 + date.getMonth())).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    return year + '-' + month + '-' + day
}
export const yyyyMMdd = (date: Date | number): string => {
    //yyyyMMdd
    const currentDate = typeof date === 'number' ? new Date(date) : date;
    const year = currentDate.getFullYear()
    const month = ('0' + (1 + currentDate.getMonth())).slice(-2)
    const day = ('0' + currentDate.getDate()).slice(-2)
    return year + month + day
}

export const dateToTimeFormat = (date: Date): number => {
    if (date) {
        date.getMinutes() >= 30 ? date.setMinutes(30) : date.setMinutes(0)

        date.setSeconds(0)
        date.setMilliseconds(0)
        return date.getTime() / 1000
    } else {
        return 0
    }
}

/**
 * 날자시간 형식의 데이터를 날자 형식으로 변환하여 반환한다.
 * @param dateTimeString 2023-04-13T11:00:00 혹은 2023-04-13 11:00:00
 * @return dateString 2023-04-13
 */
export const getYMDFromAllTime = (dateTimeString: string): string => {
    return dateTimeString.substring(0, 4)+'.'+ dateTimeString.substring(5, 7)+'.'+dateTimeString.substring(8, 10)
}

/**
 * 날자시간 형식을 변환하여 반환한다.
 * @param dateTimeString yyyy-MM-dd'T'HH:mm:ss
 * @return MM-dd HH:mm
 */
export const convertToMonthDayHourMinute = (dateTimeString: string): string => {
    return dateTimeString.substring(5, 7) + '.' + dateTimeString.substring(8, 10)+ ' '+ dateTimeString.substring(11, 16)
}

/**
 * 날자시간 형식을 추출하여 반환한다.
 * @param dateTimeString yyyy-MM-dd'T'HH:mm:ss
 * @return yyyy-MM-dd HH:mm
 */
export const extractDateTimeString = (dateTimeString: string): string => {
    return dateTimeString.substring(0, 4)+'.'+ dateTimeString.substring(5, 7)+'.'+dateTimeString.substring(8, 10)+ ' ' + dateTimeString.substring(11, 16)
}

/**
 * 시:분(HH:mm)을 추출하여 반환한다.
 * @param dateTimeString yyyy-MM-dd'T'HH:mm:ss
 * @return HH:mm
 */
export const extractHourMinute = (dateTimeString: string): string => {
    //2023-04-13T11:00:00 -> 11:00
    return dateTimeString.substring(11, 16)
}

export const timeToLabel = (time: number): string => {
    //AM 09:00
    if (time) {
        const date = new Date(time * 1000)
        const hours = date.getHours()
        const minutes = date.getMinutes()
        return `${hours.paddingHour(true)}:${`${minutes}`.padStart(2, '0')}`
    } else {
        return ''
    }
}

export function yyyymmddhhmm(date: Date): string {
    //yyyy.mm.dd HH:mm
    const year = date.getFullYear()
    const month = ('0' + (1 + date.getMonth())).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    const hours = `${date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`}`
    const minutes = `${date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`}`
    return year + '.' + month + '.' + day + ' ' + hours + ':' + minutes
}

export function yyyymmddhhmmss(date: Date): string {
    //yyyy-mm-dd HH:mm:ss
    const year = date.getFullYear()
    const month = ('0' + (1 + date.getMonth())).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    const hours = `${date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`}`
    const minutes = `${date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`}`
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00'
}

import * as React from 'react'
import 'three-dots/dist/three-dots.min.css'
import styles from '../../styles/m/login.module.css'
import LeftNavigation from './LeftNavigation'

const LoadingIndicator = () => {
    return (
        <LeftNavigation isActiveMenu={false}>
            <div className={styles.loadingIcon} style={{ position: 'absolute', top: '50%', left: '50%' }}>
                <div className={'dot-windmill'} />
            </div>
        </LeftNavigation>
    )
}

export default LoadingIndicator

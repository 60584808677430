import { NextApiRequest } from 'next'
import { Session, withIronSession } from 'next-iron-session'
import { LoginDto } from '../__generated__/data-contracts'

export type NextIronRequest = NextApiRequest & { session: Session }

export default function withSession(handler) {

    return withIronSession(handler, {
        password: '6255729e60c7ec58324341a1a9c004967c819575844cc997b64c9e794aafe6c0',
        cookieName: 'sofi',
        // if your localhost is served on http:// then disable the secure flag
        cookieOptions: {
            secure: false,
        },
    })
}

const authValidate = async (req: NextIronRequest) => {
    const user: LoginDto | undefined = await req.session.get('user')
    if (!user) {
        return {
            redirect: {
                destination: '/login',
                permanent: false,
            },
        }
    }
    return {
        props: { user },
    }
}

export { authValidate }

import {
    Error,
    FindByFloorAndCountMeetingDto,
    GetAllBuildingFloorDto,
    GetEmployeeList,
    GetFloorSeatColorDto,
    GetFloorSeatCountDto,
    HierarchyDepartmentDto,
    MapInfoDto,
    RoomReservationRes,
    SearchUserDto,
    SearchUserListDto,
    SeatSituationDto,
    UserSimpleRes,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class KioskController<SecurityDataType = unknown> {
    http: HttpClient<SecurityDataType>

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http
    }

    /**
     * @description 사용자 찾기 다음 예약까지 나옴)
     *
     * @tags kiosk-controller
     * @name SearchUser
     * @summary 사용자 찾기(다음 예약까지 나옴)
     * @request GET:/kiosk/maps/users/{userId}
     * @response `200` `(SearchUserDto)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    searchUser = (userId: number, params: RequestParams = {}) =>
        this.http.request<SearchUserDto[], Error>({
            path: `/kiosk/maps/users/${userId}`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 사용자 찾기 다음 예약까지 나옴 + 현재 상태)
     *
     * @tags kiosk-controller
     * @name SearchUserListAndWorkType
     * @summary 사용자 찾기(다음 예약까지 나옴 + 현재 상태)
     * @request GET:/kiosk/maps/users/{userId}/work-type
     * @response `200` `SearchUserListDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    searchUserListAndWorkType = (userId: number, params: RequestParams = {}) =>
        this.http.request<SearchUserListDto, Error>({
            path: `/kiosk/maps/users/${userId}/work-type`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 예약 현황 (키오스크용 비로그인)
     *
     * @tags kiosk-controller
     * @name GetSeatSituationAll
     * @summary 좌석 예약 현황(키오스크용 비로그인)
     * @request GET:/kiosk/maps/seats/{seatId}/situations/{startAt}
     * @response `200` `(SeatSituationDto)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getSeatSituationAll = (seatId: number, startAt: number, params: RequestParams = {}) =>
        this.http.request<SeatSituationDto[], Error>({
            path: `/kiosk/maps/seats/${seatId}/situations/${startAt}`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 해당 회의실 id를 통해 검색
     *
     * @tags kiosk-controller
     * @name GetRoomReservationsByRoomId
     * @summary 검색 조회 (회의실 id)
     * @request GET:/kiosk/maps/meeting-rooms/{roomId}/reservations
     * @response `200` `(RoomReservationRes)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getRoomReservationsByRoomId = (roomId: number, params: RequestParams = {}) =>
        this.http.request<RoomReservationRes[], Error>({
            path: `/kiosk/maps/meeting-rooms/${roomId}/reservations`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 빌딩,층,도면 주소
     *
     * @tags kiosk-controller
     * @name GetAll
     * @summary 빌딩 이름, 층 이름 모두 가져오기
     * @request GET:/kiosk/maps/floors
     * @response `200` `(GetAllBuildingFloorDto)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getAll = (params: RequestParams = {}) =>
        this.http.request<GetAllBuildingFloorDto[], Error>({
            path: `/kiosk/maps/floors`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 현재 도면 정보
     *
     * @tags kiosk-controller
     * @name GetFloorReservations
     * @summary 현재 도면 정보
     * @request GET:/kiosk/maps/floors/{floorId}
     * @response `200` `MapInfoDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getFloorReservations = (floorId: number, params: RequestParams = {}) =>
        this.http.request<MapInfoDto, Error>({
            path: `/kiosk/maps/floors/${floorId}`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 좌석 구역 색 가져오기
     *
     * @tags kiosk-controller
     * @name GetFloorSeatColor
     * @summary 좌석 구역 색 가져오기
     * @request GET:/kiosk/maps/floors/{floorId}/seats/colors
     * @response `200` `(GetFloorSeatColorDto)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getFloorSeatColor = (floorId: number, params: RequestParams = {}) =>
        this.http.request<GetFloorSeatColorDto[], Error>({
            path: `/kiosk/maps/floors/${floorId}/seats/colors`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 층 회의실 조회
     *
     * @tags kiosk-controller
     * @name FindByFloorAndCount
     * @summary 층 회의실 조회
     * @request GET:/kiosk/maps/floors/{floorId}/meeting-rooms
     * @response `200` `(FindByFloorAndCountMeetingDto)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    findByFloorAndCount = (floorId: number, params: RequestParams = {}) =>
        this.http.request<FindByFloorAndCountMeetingDto[], Error>({
            path: `/kiosk/maps/floors/${floorId}/meeting-rooms`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 층 별 좌석 정보
     *
     * @tags kiosk-controller
     * @name GetFloorSeatCount
     * @summary 층 별 좌석 정보
     * @request GET:/kiosk/maps/floors/seats
     * @response `200` `(GetFloorSeatCountDto)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getFloorSeatCount = (params: RequestParams = {}) =>
        this.http.request<GetFloorSeatCountDto[], Error>({
            path: `/kiosk/maps/floors/seats`,
            method: 'GET',
            ...params,
        })
    /**
     * No description
     *
     * @tags kiosk-controller
     * @name FindAllEmployees
     * @summary 전체 조회
     * @request GET:/kiosk/maps/employees
     * @response `200` `(UserSimpleRes)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    findAllEmployees = (params: RequestParams = {}) =>
        this.http.request<UserSimpleRes[], Error>({
            path: `/kiosk/maps/employees`,
            method: 'GET',
            ...params,
        })
    /**
     * No description
     *
     * @tags kiosk-controller
     * @name GetEmployees
     * @summary 부서 직원 조회
     * @request GET:/kiosk/maps/departments/{id}/employees
     * @response `200` `GetEmployeeList` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getEmployees = (id: number, params: RequestParams = {}) =>
        this.http.request<GetEmployeeList, Error>({
            path: `/kiosk/maps/departments/${id}/employees`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 계층 구조 리스트
     *
     * @tags kiosk-controller
     * @name Hierarchy
     * @summary 전체 조회 (계층 구조)
     * @request GET:/kiosk/maps/departments/hierarchies
     * @response `200` `(HierarchyDepartmentDto)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    hierarchy = (params: RequestParams = {}) =>
        this.http.request<HierarchyDepartmentDto[], Error>({
            path: `/kiosk/maps/departments/hierarchies`,
            method: 'GET',
            ...params,
        })
}
